import React from 'react';
import { Input, Form, Col, Timeline, Spin, Result, Carousel, Divider } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import './styles.css';
import moment from 'moment';
import { api } from '../api';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      q: '',
    };
  }

  formRef = React.createRef();

  componentDidMount() {
    this.search(this.props.match.params.id ? this.props.match.params.id : '');

    AOS.init({
      duration: 1000
    });
  };

  fetch = async (params = {}) => {
    this.setState({
      loading: true,
      track: null,
      error: null,
      comprobante: null,
    });
    if (this.state.q && this.state.q > '') {
      try {
        const response = await api.tracking.get(this.state.q ? this.state.q : '');
        if (response.status === "success" && response.data.tracking) {
          this.setState({
            track: response.data.tracking,
            comprobante: response.data.comprobante,
            loading: false,
          });

          var str = this.state.comprobante.guias.dfranquicia.piso
          var n = str.includes("  ");
          this.setState({ vacio: n })

          this.formRef.current.setFieldsValue({
            remitente: this.state.comprobante ? this.state.comprobante.guias.onombre : '',
            destinatario: this.state.comprobante ? this.state.comprobante.guias.dnombre : '',
            domicilio: this.state.comprobante ? this.state.comprobante.guias.dcalle +
              (this.state.comprobante.guias.dnumero > "" ? " Nº: " + this.state.comprobante.guias.dnumero : "") +
              (this.state.comprobante.guias.dpiso > "" ? " Piso: " + this.state.comprobante.guias.dpiso : "") +
              (this.state.comprobante.guias.ddpto > "" ? " Dpto.: " + this.state.comprobante.guias.ddpto : "") +
              (this.state.comprobante.guias.dlocalidad > "" ? " - " + this.state.comprobante.guias.dlocalidad : "") : '',
            dtelefono: this.state.comprobante ? this.state.comprobante.guias.dtelefono : '',
            sucursalDestino: this.state.comprobante ? this.state.comprobante.guias.dfranquicia.franquicia : '',
            telefono: this.state.comprobante ? this.state.comprobante.guias.dfranquicia.telefono : '',
            horario: this.state.comprobante ? this.state.comprobante.guias.dfranquicia.horario : '',
            direccion: this.state.comprobante ? this.state.comprobante.guias.dfranquicia.calle +
              (this.state.comprobante.guias.dfranquicia.altura > "" ? " Nº: " + this.state.comprobante.guias.dfranquicia.altura : "") +
              ((this.state.comprobante.guias.dfranquicia.piso > "") && (this.state.vacio == false) ? " Piso: " + this.state.comprobante.guias.dfranquicia.piso : "") +
              (this.state.comprobante.guias.dfranquicia.dpto > "" ? " Dpto.: " + this.state.comprobante.guias.dfranquicia.dpto : "") +
              (this.state.comprobante.guias.dfranquicia.localidad > "" ? " - " + this.state.comprobante.guias.dfranquicia.localidad : "") : '',
          });
        } else {
          this.setState({
            loading: false,
            track: null,
            error: true,
            comprobante: null,
          });
          this.formRef.current.setFieldsValue({
            remitente: '',
            destinatario: '',
            domicilio: '',
            dtelefono: '',
            sucursalDestino: '',
            telefono: '',
            horario: '',
            direccion: '',
          });
        }
      } catch (e) {
        console.log(e);
        this.setState({
          loading: false,
          track: null,
          error: null,
          comprobante: null,
        });
      }
    } else {
      this.setState({
        loading: false,
        track: null,
        error: null,
        comprobante: null,
      });
    }
  };

  search = (text) => {
    this.setState({ q: text }, () => {
      this.fetch();
    })
  };

  render() {
    const search = (
      <>
        <div className="divider my-3" />
        <div className="d-flex justify-content-center" >
          <img src="assets/imagenes/iconocliente.png" style={{ width: '50px', height: '50px' }} className="img-fluid" />
        </div>
        <div className="mr-2 ml-2" >
          <hr style={{ borderTop: '2px solid #8D92D4', width: '100%', marginTop: 5 }} />
          <div style={{ color: '#FFFFFF', fontSize: '12px' }}> N° de seguimiento</div>
          <Form ref={this.formRef} initialValues={{ codigoseguimiento: this.props.match.params.id ? this.props.match.params.id : '' }}>
            <Col span={24}>
              <Form.Item label="" name={"codigoseguimiento"} rules={[{ required: true, message: ' ' }]}>
                <Input.Search
                  placeholder="Número"
                  loading={this.state.loading}
                  onSearch={value => this.search(value)}
                />
              </Form.Item>
            </Col>
            <Divider style={{ color: '#FFFFFF', fontSize: '14px' }}>Remitente</Divider>
            <div style={{ color: '#FFFFFF', fontSize: '12px' }}> Nombre y apellido</div>
            <Col span={24}>
              <Form.Item label="" name="remitente">
                <Input readOnly />
              </Form.Item>
            </Col>
            <Divider style={{ color: '#FFFFFF', fontSize: '14px' }}>Destinatario</Divider>
            <div style={{ color: '#FFFFFF', fontSize: '12px' }}> Nombre y apellido</div>
            <Col span={24}>
              <Form.Item label="" name="destinatario">
                <Input readOnly />
              </Form.Item>
            </Col>
            <div style={{ color: '#FFFFFF', fontSize: '12px' }}> Teléfono</div>
            <Col span={24}>
              <Form.Item label="" name="dtelefono">
                <Input readOnly />
              </Form.Item>
            </Col>
            <div style={{ color: '#FFFFFF', fontSize: '12px' }}> Domicilio</div>
            <Col span={24}>
              <Form.Item label="" name="domicilio">
                <Input.TextArea readOnly />
              </Form.Item>
            </Col>
            <Divider style={{ color: '#FFFFFF', fontSize: '14px' }}>Sucursal destino</Divider>
            <div style={{ color: '#FFFFFF', fontSize: '12px' }}> Sucursal </div>
            <Col span={24}>
              <Form.Item label="" name="sucursalDestino">
                <Input readOnly />
              </Form.Item>
            </Col>
            <div style={{ color: '#FFFFFF', fontSize: '12px' }}> Teléfono </div>
            <Col span={24}>
              <Form.Item label="" name="telefono">
                <Input readOnly />
              </Form.Item>
            </Col>
            <div style={{ color: '#FFFFFF', fontSize: '12px' }}>  Dirección </div>
            <Col span={24}>
              <Form.Item label="" name="direccion">
                <Input.TextArea readOnly />
              </Form.Item>
            </Col>
            <div style={{ color: '#FFFFFF', fontSize: '12px' }}> Horario </div>
            <Col span={24}>
              <Form.Item label="" name="horario">
                <Input.TextArea readOnly />
              </Form.Item>
            </Col>
          </Form>
        </div>
      </>
    );

    return (
      <div className="fuente" style={{ background: '#F8F8F8' }} >
        <div className="container-fluid no-breadcrumb container-mw-lg chapter" >
          <div className="row container-mw-lg">
            <div className="col-md-9 pr-0 pl-0">

              <div className="header" >
                <div data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                  <a href="https://credifin.com.ar/" target="_blank">
                    <img src="assets/imagenes/logo.svg" width="200px" className="img-fluid" />
                  </a>
                </div>
              </div>

              <div className="container py-5 px-5">

                <div data-aos="flip-left">
                  <h3 className="titulo text-md-left text-center"> Seguimiento Online</h3>
                </div>

                <div data-aos="fade-up">
                  <Carousel autoplay>
                    <div>
                      <img src="assets/imagenes/carrusel-1.jpg" className="img-fluid" />
                    </div>
                    <div>
                      <img src="assets/imagenes/carrusel-2.jpg" className="img-fluid" />
                    </div>
                  </Carousel>
                </div>

                {this.state.loading == true &&
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="pt-5" >
                    <Spin size="large" />
                  </div>
                }

                <div className="pt-5" style={{ float: 'left', width: '80%' }} id="foo">
                  <Timeline mode="left" >
                    {this.state.track && this.state.track.map((data, index) => {
                      return <Timeline.Item
                        dot={index === 0 &&
                          <CheckCircleFilled
                            style={{
                              color: 'rgb(255 255 255)',
                              position: 'absolute',
                              fontSize: '19px',
                              right: '-7px',
                              top: '-4px',
                              borderRadius: "100%",
                              boxShadow: 'rgb(18, 38, 170) 0px 0px 3px 1px'
                            }}
                          />}
                        label={moment.parseZone(data.fecha).format('DD/MM/YY HH:mm')}
                        value={data.id} key={index} style={index === 0 ? { color: '#1226aa', fontWeight: 'bold' } : {}}
                      >
                        {data.descripcion}
                      </Timeline.Item>
                    })}
                  </Timeline>
                </div>

                <div style={{ display: !this.state.error ? 'none' : 'block' }}>
                  <div>
                    <Result
                      status="error"
                      title="Ingrese un número de seguimiento correcto"
                    >
                    </Result>
                  </div>
                </div>
              </div>

            </div>

            <div className="col-md-3 search">
              {search}
            </div>

          </div>
        </div>

      </div >
    );
  }
}
